import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const DebtManagementPage = () => {
    const article = {
        id: '7e0e183f-a2b8-5dd3-a934-745281108cc8',
        title: 'The Most Effective Ways to Manage Your Debt',
        slug: '/debt-management/',
        date: '2018-01-24T18:45:48.000Z',
        modified: '2021-11-02T19:10:03.000Z',
        excerpt: 'Dealing with debt can be tough on not just individuals, but their families too. In this guide, we&#8217;ll explore the importance of debt management, as well as how to handle things when it comes to your family.',
        featured_image: {
            source_url: '/media/debt-management-featured-0.png',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 18,
        content: (
            <>
                <p>People go into debt for many different reasons, and not all of those reasons are necessarily &quot;bad.&quot;</p>
                <p>The reason for your debt may not be overspending on luxuries, but rather overspending on your children, buying things for their future, etc. </p>
                <p>
                    You want to give your children the best
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=debt-management&amp;sub2=life" rel="noopener noreferrer" target="_blank">life</a>
                    {' '}
                    you possibly can, but sometimes the stress of that debt can cause you to react poorly to minor annoyances.
                    {' '}
                </p>
                <p>
                    According to a
                    {' '}
                    <a target="_blank" href="http://www.cnn.com/2013/08/07/living/parents-financial-stress-spanking/?iref=obinsite" rel="noreferrer">recent study</a>
                    , moms who were going through intense financial stress were more likely to yell at their kids and spank their kids.
                    {' '}
                </p>
                <p>This is one of several studies suggesting that financial stress and excessive debt can be harmful to children&rsquo;s well being.</p>
                <p>Here are a few ways to deal with your financial stress without letting it interfere in your relationship with your child.</p>
                <h3>Take care of yourself first</h3>
                <p>Even if you are dealing with a lot of debt, try to find easy, low-cost ways to exercise, socialize and relax. </p>
                <p>Go for walks in the park. </p>
                <p>Get together with friends for potluck dinners at home. </p>
                <p>Take a yoga or meditation class at a community center. </p>
                <p>As the parent to your children, your physical and mental health is important – not just for yourself, but for your kids. </p>
                <p><LazyLoadImage src="/media/best-ways-to-avoid-burnout-by-occupation-1.png" alt="Best ways to avoid burnout by occupation" /></p>
                <p>When you are feeling happy and healthy, your kids will be happier and healthier as well.</p>
                <h3>Be grateful for your kids</h3>
                <p>Even if money is tight and bills are late, take time every day to hug your children. </p>
                <p>Remind yourself of how grateful you are for your kids. </p>
                <p>These early years with your kids at home will never be repeated – someday, before you know it, they&rsquo;re going to grow up and move away. </p>
                <p>Look for little things to enjoy and appreciate every day, and look for low-cost or no-cost experiences you can share with your kids, even if you have no money left in your bank account.</p>
                <h3>Take control of what you can</h3>
                <p>Often when parents are angry with their children – yelling at their children or lashing out in frustration – it&rsquo;s because the parents feel powerless. </p>
                <p>Kids can often be very willful and obstinate – creating dozens of little problems and messes and often defying their parents right at the moment when parents need them to cooperate the most. </p>
                <p>
                    When parents are feeling financial stress or worrying about
                    {' '}
                    <a href="https://www.debtconsolidation.com/medical-bills/">varying types of debt</a>
                    , these little frustrating moments can suddenly appear massively significant.
                    {' '}
                </p>
                <p>Parenthood can be frustrating for &quot;control freaks&quot; because there is so little that we can truly control. </p>
                <p>So try to focus on a few things that you can control and that you can proactively change about your personal finances. </p>
                <p>
                    Can you set a budget, start a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=debt-management&amp;sub2=savings-account" rel="noopener noreferrer" target="_blank">savings account</a>
                    , pay off a debt?
                    {' '}
                </p>
                <p>Start today. </p>
                <p>Take small steps to feel more in control of your finances, and you&rsquo;ll likely feel more patient and forgiving of your children.</p>
                <h2 id="how-to-share-financial-troubles-with-your-children">How to share financial troubles with your children</h2>
                <p>This also raises another question: should you share your financial woes with your children?</p>
                <p>When Sarah Taylor was struggling to pay bills after buying her first house, her son began to notice that they weren&rsquo;t eating out as often or going shopping and began asking questions. </p>
                <p>Taylor, a manager at www.Admission-essays.org, wasn&rsquo;t sure if she should come clean with her child about her financial struggles or keep him in the dark. </p>
                <p>&quot;At first I was afraid, even ashamed, of telling him the truth. But then I decided to look at the situation from the outside and realized it could be quite a useful experience for my kid,&quot; Taylor said.</p>
                <p>
                    Mary Hunt, author of&nbsp;
                    <a target="_blank" href="http://amzn.com/0800721411" rel="noreferrer">Raising Financially Confident Kids</a>
&nbsp;and the founder of&nbsp;
                    <a target="_blank" href="http://www.debtproofliving.com/" rel="noreferrer">Debt Proof Living</a>
&nbsp;agrees that it is important to talk with your children about your family&rsquo;s financial situation because otherwise they may interpret the situation to be worse than it is.
                    {' '}
                </p>
                <p>However, the way that you present the situation can impact their lifelong attitudes towards money.</p>
                <p>&quot;As hard as it is, parents need to change their mindset when talking with their children from being an embarrassed parent in debt to being a teacher,&quot; Hunt says. </p>
                <p>She recommends that parents use their current situation as a teaching moment their child to hopefully avoid accumulating debt as a grown up.</p>
                <h3>What exactly should you tell your children?</h3>
                <p>However, it is not in the parents or especially the child&rsquo;s, best interest to give them the sordid details of the debt. </p>
                <p>&quot;Parents need to be very careful about sharing money troubles with their kids. They have to walk a fine line in order not to worry their children too much, causing them to feel insecure and anxious,&quot; says Carole Lieberman M.D., Psychiatrist. </p>
                <p>She also recommends taking into account the age and psychological maturity of your children when deciding how much to tell them.</p>
                <p>Hunt recommends first explaining debt as a promise to pay someone back with money that you currently don&rsquo;t have. </p>
                <p>&quot;Put it in terms that they can understand, such as them wanting a toy from the store and then paying back the owner with money they earn from mowing yards. Tell them that after a few weeks that they no longer like the toy anymore and lose their job mowing lawns but still have to pay back the money.&quot; Hunts says. </p>
                <p>Adjust the example to your child&rsquo;s interest and to age so that they can relate the concept to their own experiences.</p>
                <p>Once your child understands the concept of debt, explain that you made some bad choices with money and are now living with the consequences of paying back money that you don&rsquo;t have. </p>
                <p>Hunt cautions parents against telling their children how much money they owe creditors. </p>
                <p>&quot;How much you owe is an adult sized worry that they don&rsquo;t need to be burdened with,&quot; Hunt says. </p>
                <p>Since many children will worry if they will lose their home or have enough to eat, assure your child that your family will be OK and that they will be taken care of. </p>
                <p>Encourage your child to ask any questions and answer them honestly based on your child&rsquo;s maturity</p>
                <h3>Involving your child in getting the family on track</h3>
                <p>Since paying off your debt will most likely affect your child&rsquo;s lifestyle, involve them in your goals in a positive way. </p>
                <p><LazyLoadImage src="/media/share-of-families-with-debt-2.png" alt="Share of families with debt" /></p>
                <p>Let them know the areas that your family will be cutting back, such as eating out or any after school lessons that they will have to take a break from. Be sure to point out all of the positive aspects that will not change and all of the things that your family enjoys doing together that doesn&rsquo;t cost any money.</p>
                <p>
                    When your child asks to buy
                    {' '}
                    <a href="https://www.debtconsolidation.com/stick-to-your-budget/">something that isn&rsquo;t in the budget</a>
                    , Hunt says that it is important to phrase your answer in way that properly frames your family&rsquo;s value of money.
                    {' '}
                </p>
                <p>&quot;Instead of saying that we can&rsquo;t afford the toy, tell your child that we are choosing not to buy the toy because you are choosing to spend your money in other ways, such as paying off the debt or saving,&quot; Hunt says. </p>
                <p>&quot;You don&rsquo;t want kids to give money power over their life, but to realize that we think before we spend money.&quot;</p>
                <p>Taylor found that by reminding her son that even though the family was a on a budget that they could afford many of the things he still enjoyed. </p>
                <p>Additionally, her son was willing to pitch in and within a year the family got back on track financially. </p>
                <p>&quot;Our family has become closer and now we know that everything can be bearable if we are together,&quot; Taylor says.</p>
                <h2 id="helping-an-adult-child-become-debt-free">Helping an adult child become debt-free</h2>
                <p>Maybe your situation is different than the one we talked about above.</p>
                <p> Maybe you have plenty of life experience under your belt, but it&rsquo;s your adult child that is struggling with debt. </p>
                <p>The decision to help your child is easy, but the right way to go about doing so can cause confusion.</p>
                <p>
                    Here are a few tips for how to help your child
                    {' '}
                    <a href="https://www.creditloan.com/blog/us-credit-card-debt/">get out of credit card debt</a>
                    {' '}
                    &quot;the right way,&quot; and won&rsquo;t damage your child&rsquo;s financial future or your relationship.
                </p>
                <h3>Loan from the &quot;Bank of Mom and Dad&quot;</h3>
                <p>
                    If your adult child (age 18 or over) has racked up some
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=debt-management&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    debt, and you want to help them repay the debt, you can issue them a &quot;
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=debt-management&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    &quot; by charging them interest and signing a contract, just like you would if you were a loan officer at a bank.
                    {' '}
                </p>
                <p>Why is it best to charge interest, even to family members? </p>
                <p>Because interest and clear expectations make it more likely that the loan will be repaid. </p>
                <p>It&rsquo;s better to establish a clear understanding of when and how the loan must be repaid, rather than risk damaging your relationship in the long run with an unpaid debt – or enabling irresponsible financial behavior from your child.</p>
                <h3>Co-sign on a loan</h3>

                {' '}
                <p>Depending on how much debt your child has amassed, you might consider being a co-signer on a personal debt consolidation loan. </p>
                <p>The advantage of being a co-signer is that you can use your own credit history to help your child qualify for a better loan with more favorable interest rates and terms than they might have gotten on their own. </p>
                <p>The disadvantage is, as a co-signer, you are equally responsible for repaying your child&rsquo;s debt. If the child fails to repay, the bank can come after you to collect on the debt.</p>
                <h3>Help your child help themselves</h3>
                <p>
                    If your child&rsquo;s debt is severe enough, another option to help your child get out of
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=debt-management&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    debt is to help connect your child with services like&nbsp;
                    <a target="_blank" href="https://www.debtconsolidation.com/credit-counseling/" rel="noreferrer">credit counseling</a>
                    ,&nbsp;
                    <a target="_blank" href="https://www.debtconsolidation.com/debt-settlement/" rel="noreferrer">debt settlement</a>
                    , or other options to negotiate with credit card companies.
                    {' '}
                </p>
                <p>Instead of spending your own money or risking your own credit score, you could help your child find a good consumer credit counseling agency or&nbsp;debt consolidation&nbsp;firm that could offer assistance. </p>
                <p>And as part of that experience, you could help your child find good budgeting tools and talk with your child about how to make better financial decisions in the future.</p>
                <h2 id="5-debt-facts-that-young-adults-can-use-right-now">5 debt facts that young adults can use right now</h2>
                <p>Whether you&rsquo;re the young adult figuring your way through the burden of debt or a parent imparting advice to a child, there are five basic facts about debt that everyone should know. </p>
                <h3>1. You can have a powerful impact on your future finances</h3>
                <p>It&rsquo;s all a matter of time and compound interest. Money saved early is worth more than money saved later. </p>
                <p>A 19-year-old college freshman who saved $100 a month between 19 and 24 would graduate with $7,200, assuming average returns of 7% a year. </p>
                <p>At the same rate, that $7,200 would be worth $107,000 upon retiring at 65. </p>
                <p>
                    Putting $100 worth of expenses on credit cards at the&nbsp;
                    <a target="_blank" href="http://www.indexcreditcards.com/credit-card-rates-monitor/" rel="noreferrer">current average interest of 17.42 percent</a>
                    {' '}
                    would mean $9,600 in debt upon graduation.
                    {' '}
                </p>
                <p>If saved, that would have become $143,000 by retirement. </p>
                <p>That&rsquo;s a difference of a quarter million dollars.</p>
                <h3>2. Student loans are available in abundance</h3>
                <p>And it&rsquo;s a good thing, too. </p>
                <p>
                    Tuition costs nationwide&nbsp;
                    <a target="_blank" href="http://nces.ed.gov/fastfacts/display.asp?id=76" rel="noreferrer">rose 596% between 1980 and 2011</a>
                    .
                    {' '}
                </p>
                <p>
                    During the same period,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=debt-management&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    went from representing about 20% of how students paid tuition, to being responsible for 50% of student financial support.
                    {' '}
                </p>
                <p>
                    The availability of
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=debt-management&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    makes it possible for young adults to make an investment in their future at a time that they&rsquo;re less likely to have the distracting responsibilities of family or career.
                </p>
                <h3>3. An education doesn&rsquo;t necessarily mean a better job</h3>
                <p>
                    Earnings are still higher overall for those with a degree — about $250,000 over the course of a career according to a&nbsp;
                    <a target="_blank" href="http://cew.georgetown.edu/collegepayoff/" rel="noreferrer">Georgetown University report on education and the workforce.</a>
&nbsp;
                </p>
                <p>However, this increase isn&rsquo;t consistent across all majors. STEM (science, technology, engineering and math) degrees result in higher paying jobs, while liberal arts, education and social work degrees earn little more than a high school diploma. </p>
                <p>Young adults who want to borrow money to pay for a degree should consider the value of the investment before committing.</p>
                <h3>4. Live on a budget from day one</h3>
                <p>According to the American Dream Education Campaign, young adults overwhelmingly report beginning adult life unprepared for the complexity of personal finances. </p>
                <p>As costs rise and the economy puts adults with better resumes in the jobs previously held by college students, this has led to a 16% increase in student loan debt and a 24% increase in credit card balances upon graduation, according to a report by the National Endowment for Financial Education. </p>
                <p>Living on a budget helps to avoid the kind of debt that limits opportunities upon graduation.</p>
                <p><LazyLoadImage src="/media/types-of-budgeting-systems-used-by-millenials-3.png" alt="Types of budgeting systems used by millenials" /></p>
                <h3>5. Credit card companies know your weaknesses</h3>
                <p>Teens have a long life of payments ahead of them, and aren&rsquo;t developmentally capable of making smart long-term decisions — which is why the U.S. Public Interest Research Group raised concern that 80% of young adult students had received direct mail from credit card companies, and 22% received four phone calls per month offering credit cards. </p>
                <p>
                    Although the&nbsp;
                    <a target="_blank" href="http://files.consumerfinance.gov/f/201303_cfpb_final-rule_regulation-z-amendment.pdf" rel="noreferrer">CARD act of 2009</a>
&nbsp;aimed to curb that kind of predatory advertising, banks and credit card companies still pay colleges for access to student contact information and physical access to campus in order to continue targeting young adults.
                </p>
                <h2 id="getting-out-of-debt-once-and-for-all">Getting out of debt once and for all</h2>
                <p>Once you&rsquo;ve been in debt, you never want to be there again—especially if it put a strain on your family. </p>
                <p>There are five tactics you can apply to your existing debt to help you get on top of it. </p>
                <h3>1. Debt snowball method</h3>
                <p>
                    The phrase&nbsp;
                    <a target="_blank" href="http://www.daveramsey.com/article/get-out-of-debt-with-the-debt-snowball-plan/" rel="noreferrer">&quot;debt snowball&quot; was popularized</a>
&nbsp;by financial guru Dave Ramsey.
                    {' '}
                </p>
                <p>However, the basic outline for the debt snowball has been around for quite some time. </p>
                <p>With the debt snowball, you list all of your debts, starting with the smallest amount.</p>
                <p>Money designated as debt reduction is applied to the smallest debt, on top of the minimum payment amount. Meanwhile, you continue making minimum payments on other debts. </p>
                <p>When the first debt is paid off, the entire amount (debt reduction plus minimum payment) is applied to the next debt on the list, on top of its minimum payment. </p>
                <p>Over time, the debt reduction payments grow, and the debt is retired a little faster.</p>
                <h3>2. Debt avalanche method</h3>
                <p>Even though the process for the debt avalanche is the same as that followed in the debt snowball, the order in which debts are paid off is different. </p>
                <p>Rather than starting with the smallest debt, the debt avalanche encourages you to start with the highest interest debt. </p>
                <p>
                    A study from the University of Michigan,&nbsp;
                    <a target="_blank" href="http://webuser.bus.umich.edu/srick/Winning%20the%20Battle.pdf" rel="noreferrer">&quot;Winning the Battle but Losing the War: The Psychology of Debt Management</a>
                    ,&quot; found that it&rsquo;s more financially effective to tackle the highest-interest debt first — no matter how large the debt is.
                </p>
                <p>However, the study also found that many consumers are averse to this method and psychologically prefer the quick victory of paying off the smaller debt, even though ordering debts this way, without regard to interest rate, can be more expensive and keep consumers in debt longer.</p>
                <h3>3. Debt snowflaking</h3>
                <p>Debt snow-flaking is more of an addition to the debt snowball or the debt avalanche method. </p>
                <p>To &quot;snowflake&quot; your debt, you choose either the snowball or the avalanche and follow that plan. </p>
                <p>Then, to boost debt pay down, you look for ways to add a little more to the plan. </p>
                <p>&quot;Snowflake&quot; amounts are small and paid as soon as you get them. </p>
                <p>These amounts can come from cutting something additional out of your budget, or by making extra money with a side job or by selling some of the items sitting in your attic. </p>
                <p>With snowflakes added to a snowball or avalanche, debt can be retired a little bit faster.</p>
                <h3>4. Debt siege</h3>
                <p>
                    According to a study from Ohio State University published in the January 2013 issue of the journal&nbsp;
                    <a target="_blank" href="http://www.weai.org/EI" rel="noreferrer">Economic Inquiry</a>
                    , young people are adding credit card debt at a faster rate than ever — and they are paying it off more slowly.
                    {' '}
                </p>
                <p>Many young people appear to be somewhat comfortable carrying a certain amount of debt, and that leads to a debt reduction method known as the siege.</p>
                <p>With this method, steps to pay off debt by aggressively cutting back on spending, or snow-flaking with additional income, are not employed. </p>
                <p>Instead, the debt siege focuses more on comfort and paying off debt in a leisurely manner that doesn&rsquo;t require huge changes to spending behavior. </p>
                <p>It takes longer (the Ohio State study indicates some of the current generation will be comfortable carrying debt until they die), and more is paid in interest, but it is also a little more bearable — if you are not as uncomfortable with the idea of carrying debt.</p>
                <p>Consider which method is most likely to benefit your psychological situation, as well as your financial situation, and decide which will work best for you.</p>
                <h3>5. Balance transfers</h3>
                <p>If you need more options, you might consider using a balance transfer. </p>
                <p>Credit card companies offer balance transfers, typically with a zero interest introductory rate for six, 12 or 18 months, in order to attract new customers.</p>
                <p>That said, these companies are not doing this because they want to help you get out of debt. </p>
                <p>While the introductory period offers you an opportunity to pay off a credit card balance faster, the credit card companies are hoping you&rsquo;ll neglect to pay it off in full and will pay them the interest you had been sending to another company.</p>
                <p>Savvy credit card users can use a balance transfer to their advantage, but be aware that balance transfer offers are typically only offered to consumers with very good credit. </p>
                <p>If your debt problems have dinged your credit score, you may not qualify for a balance transfer.</p>
                <p><LazyLoadImage src="/media/credit-score-distribution-in-2017-4.png" alt="Credit score distribution in 2017" /></p>
                <p>For example, if you have a credit card balance of $5,000 and you have $300 per month to apply to that payment, it will take you 20 months to eliminate the balance on a credit card that charges 19 percent interest. </p>
                <p>If you switch to a zero percent interest card you can pay it off in 16 months.</p>
                <p>Keep the following features in mind as you look for a balance transfer card:</p>
                <p>
                    <strong>Balance transfer fees. </strong>
                    Most credit card companies charge 3- to 5- percent of the balance you&rsquo;re transferring, so use a balance transfer calculator to figure out whether that fee is higher than your interest
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=debt-management&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    .
                    {' '}
                </p>
                <p>For example, you&rsquo;ll pay between $150 and $250 to transfer a $5,000 balance. </p>
                <p>Search credit card websites to see if you can find a credit card offer without a transfer fee, but be aware that the credit card issuer is likely to compensate for the lack of a fee with a shorter zero percent interest period or a higher interest rate after the introductory period.</p>
                <p>
                    <strong>Length of the introductory rate. </strong>
                    The length of the zero percent interest period is extremely important, because in order to make a balance transfer worthwhile you need to pay off the balance before the introductory rate expires.
                    {' '}
                </p>
                <p>Make sure you&rsquo;ve done your math homework and calculated exactly how much you need to pay each month in order to pay your balance in full before you start paying interest again. </p>
                <p>For example, if you want to pay off a $5,000 balance and your introductory period lasts 12 months, you&rsquo;ll need to pay $417 per month to pay it off before the zero interest rate expires.</p>
                <p>
                    <strong>Post-intro interest rate. </strong>
                    The interest rate you pay after the introductory period won&rsquo;t matter if you pay the balance in full and don&rsquo;t plan to charge additional items on your credit card.
                    {' '}
                </p>
                <p>However, you should be careful to compare rates in case you don&rsquo;t manage to pay the balance completely in time. </p>
                <p>You don&rsquo;t want to waste your money paying a balance transfer fee and then end up with an interest rate the same or higher than your current credit card.</p>
                <p>
                    <strong>Annual fee.</strong>
                    {' '}
                    Some credit card companies offer cards without an annual fee, but other companies charge fees that range from $15 to $300.
                    {' '}
                </p>
                <p>Add the annual fee to the balance transfer fee as part of your calculation to determine whether your interest savings will offset the cost of the transfer.</p>
                <h2 id="how-to-stick-with-your-debt-management-plan">How to stick with your debt management plan</h2>
                <p>Regardless of the method you choose to help you become debt free, you&rsquo;ll need one trait above all else to succeed: discipline. </p>
                <p>You&rsquo;ll also need to consistently make good choices. </p>
                <p>We turned to experts for some important tips that will help you avoid the worst mistakes. </p>
                <p>These strategies will help you stick with your plan, and they can even help make it more fun along the way!</p>
                <p>
                    <strong>1. Get some distance from your credit cards.</strong>
&nbsp;You don&rsquo;t have to go as far as cutting up your cards, but get them out of your wallet.
                    {' '}
                </p>
                <p>Scott Maxwell, Vice President of Wealth and Income Planning at Talis Advisors, even suggests placing some obstacles between yourself and online temptations: &quot;Removing credit card numbers from online stores forces you to type it in each time you buy something,&quot; he says.</p>
                <p>&quot;That extra step will give you time to reconsider.&quot;</p>
                <p>
                    <strong>2. Ask these two important questions.</strong>
                    {' '}
                    &quot;&lsquo;Do I need it?&rsquo; and, &lsquo;If so, why?&rsquo;&quot; says Gene Natali, Jr., author of&nbsp;
                    <a target="_blank" href="http://www.themissingsemester.com/" rel="noreferrer">The Missing Semester</a>
&nbsp;(which covers financial planning for young people).
                    {' '}
                </p>
                <p>&quot;The answer to the first question is often a resounding yes, but often times the second question brings us to reality, and helps to avoid spontaneous purchases with money that could be going to reduce your debt — and the accompanying interest payments.&quot;</p>
                <p>
                    <strong>3. Pay more than the minimum.</strong>
&nbsp;Don&rsquo;t let your spending habits dictate your payback discipline.
                    {' '}
                </p>
                <p>Beating the interest on payments is a lot like getting that percentage back as an investment return. </p>
                <p>And the investment is your good financial health. </p>
                <p>Modify your luxury spending to increase your monthly debt-management results.</p>
                <p>
                    <strong>4. Talk about and document your progress.</strong>
&nbsp;Communicate your debt-management goals to a friend, join an online support community, or hire a coach.
                    {' '}
                </p>
                <p>
                    These are all strategies espoused by&nbsp;
                    <a target="_blank" href="http://www.linkedin.com/in/chicagolifecoach" rel="noreferrer">Niquenya Fulbright</a>
                    , a life coach and founder of Learning About Debt, Investments, &amp; Economic Sustainability – an online group that focuses on women&rsquo;s personal finances.
                    {' '}
                </p>
                <p>&quot;Schedule regular meetings or phone calls with your accountability partner to talk through your struggles and successes. Journaling is [also] a highly effective method of staying on track. It can be useful just to see how far you have come.&quot;</p>
                <p>
                    <strong>5. Give yourself a break.</strong>
&nbsp;Success at a debt-management plan isn&rsquo;t about deprivation.
                    {' '}
                </p>
                <p>&quot;Decide which entertainment activities are really important to you,&quot; says Fulbright. </p>
                <p>&quot;Perhaps it&rsquo;s that one Amazon Kindle purchase, a monthly date night with your significant other, or a trip to the movies. By setting aside cash specifically for entertainment, you won&rsquo;t feel like you&rsquo;re missing out and succumb to a spending splurge. The key here is moderation.&quot;</p>
                <h2 id="the-post-debt-life-and-making-sure-you-never-return-to-the-land-of-debt-again">The post-debt life, and making sure you never return to the land of debt again</h2>
                <p>After&nbsp;getting out of debt,&nbsp;Mary and her husband are now in a better financial position than ever before. </p>
                <p>They moved to a new state with a lower cost of living, had two children in five years, rented out their old house in Chicago, bought and sold a second home, and were able to buy their &quot;dream home&quot; after saving up for a 20 percent down payment. </p>
                <p>&quot;It&rsquo;s taken us over 8 years, but we were eventually able to reduce our debt entirely and we no longer owe anything except the mortgages on our two homes,&quot; said Mary. </p>
                <p>&quot;We&rsquo;ve stayed out of debt because we stopped buying things we couldn&rsquo;t afford. Plain and simple. If we know we can&rsquo;t pay it off the next month, we don&rsquo;t charge it. Having two children in the last five years provided extra motivation to stay on track. It wasn&rsquo;t just our own financial futures on the line, but now we knew that our children&rsquo; quality of life depended on our decisions.&quot;</p>
                <h3>Save for the future</h3>
                <p>Mary and her husband are now dedicated to saving money for the future. </p>
                <p>They take full advantage of the retirement plan and matching savings offered by Mary&rsquo;s employer and they have set up Roth IRAs and college savings plans set up for each of their children as well. </p>
                <p>
                    In addition to long-term savings for retirement and their children&rsquo;s future college educations, Mary and her husband have set up a money market
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=debt-management&amp;sub2=savings-account" rel="noopener noreferrer" target="_blank">savings account</a>
                    {' '}
                    with automatic deposit from their checking account each month.
                    {' '}
                </p>
                <p>&quot;This is our liquid savings,&quot; said Mary. &quot;If we want something, we dip into that savings as needed—nothing goes on a credit card if we can&rsquo;t pay it off in full the next month. We&rsquo;ve been very intentional in cushioning this account so we have access to one full year&rsquo;s salary if needed.&quot;</p>
                <h3>Limit credit card use</h3>
                <p>Mary and her husband still use credit cards, but they only have 2 major credit cards and no more department store cards. </p>
                <p>Mary says that they&nbsp;only use credit cards to help earn rewards points to take vacations. </p>
                <p><LazyLoadImage src="/media/americans-preferred-types-of-vacations-in-2017-5.png" alt="Americans preferred types of vacations in 2017" /></p>
                <p>&quot;Those cards provide us with travel points that we use for family vacations,&quot; she said. </p>
                <p>&quot;But speaking of vacations, we haven&rsquo;t been on a major vacation in years. We only go on weekend getaways and try to research everything in advance to get the best deal on hotels (using our credit card travel points), restaurants (using daily deal sites like Groupon), and admission to attractions. Travel can be done cheaply if you plan ahead.&quot;</p>
                <h2 id="managing-debt-the-right-way">Managing debt the right way</h2>
                <p>Like Mary and the other examples throughout this article, you too can be debt free without putting a huge burden on your family.</p>
                <p>Apply the steps you&rsquo;ve found here to your own life. </p>
                <p> Start saving money for the future, pay off credit cards bit by bit, and build your way up from there. </p>
                <p>In time, you&rsquo;ll find your debt disappearing and your quality of life improving.</p>
                <p>How do you manage your debt?</p>
                <p>Do you have any advice to share with the rest of us?</p>
                <p>Let us know in the comments below. </p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default DebtManagementPage;
